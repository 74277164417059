import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {DeploymentImage} from "../domain/deployment-image.interface";

@Injectable({
  providedIn: 'root'
})
export class ImageVersionService {

  private readonly SERVICE_URL = `${environment.backendUrl}/image-version`;

  constructor(private http: HttpClient) {
  }

  public getImageVersion(imageName: string): Observable<DeploymentImage> {
    return this.http.get<DeploymentImage>(this.SERVICE_URL + '/' + imageName);
  }

  public getLatestRcImage(): Observable<DeploymentImage> {
    return this.http.get<DeploymentImage>(this.SERVICE_URL + '/latest-rc-version');
  }

}
