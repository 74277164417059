import {Component, Inject, OnInit} from '@angular/core';
import {Device} from "../../../../domain/device.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DeviceService} from "../../../../services/device.service";
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../services/notification.service';

export interface DialogData {
  activeSerial: string;
}

interface ErrorDetail {
  category: String,
  elementHint: String,
  message: String,
  position: String
}

@Component({
  selector: 'eis-gateway-edit-csv-dialg',
  templateUrl: './edit-csv-dialog.component.html',
  styleUrls: ['./edit-csv-dialog.component.scss']
})
export class EditCSVDialogComponent implements OnInit {

  file: File | null;
  step: number = 1;
  checkDeviceErrors: ErrorDetail[] = [];
  validationDeviceErrors: ErrorDetail[] = [];
  csvDeviceErrors: ErrorDetail[] = [];
  device: Device;
  deleteDatapoints: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {device: Device, activeSerial: string},
              public dialogRef: MatDialogRef<EditCSVDialogComponent>,
              public deviceService: DeviceService,
              private clipboard: Clipboard,
              private translateService: TranslateService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.device = this.data.device;
  }

  checkCSV() {
    this.deviceService.checkCreateDevice(
      this.device.deviceName!!,
      this.device.ipAddress!!,
      this.device.portNumber!!,
      this.device.unitId!!,
      this.device.gatewaySerial!!,
      this.device.aggregationBatchSize!!,
      this.file!!,
      this.device.labels!!,
      []
    ).subscribe({
      next: (errorResults) => {
        this.checkDeviceErrors = errorResults;
        this.validationDeviceErrors = this.checkDeviceErrors.filter((e) => e.category == "Conflict");
        this.csvDeviceErrors = this.checkDeviceErrors.filter((e) => e.category != "Conflict");
        this.step = 2;
      }
    });
  }

  fileChange($event: Event) {
    this.file = ($event.target as HTMLInputElement).files![0];
  }

  get checkEnabled(): boolean {
    return (this.device != null && this.file != null);
  }

  confirmDevice() {
      this.deviceService.updateDeviceCSV(
        this.device.id!!,
        this.device.deviceName!!,
        this.device.ipAddress!!,
        this.device.portNumber!!,
        this.device.unitId!!,
        this.device.gatewaySerial!!,
        this.device.aggregationBatchSize!!,
        this.file!,
        this.device.labels!!,
        this.deleteDatapoints
      ).subscribe({
        next: (response) => this.dialogRef.close({
          status: "200",
          deletedDatapoints: response.deletedDatapoints,
          createdDatapoints: response.createdDatapoints,
        }),
        error: (err) => {
          if (err.status == 409) {
            this.dialogRef.close({
              status: err.status.toString(),
              updatedDatapoints: 0
            })
          }
          this.dialogRef.close({
            status: err.status.toString()
          })
        }
      });
    }

  async copyErrorToClipboard() {
    if(this.csvDeviceErrors.length == 0) {
      return;
    }

    const errors = [];
    errors.push(this.translateService.instant("add-device-dialog.csv.error"));
    for(let csvError of this.csvDeviceErrors) {
      let errorAsString = "Line: " + csvError.position + "\n";
      errorAsString += "Element hint: " + csvError.elementHint + "\n";
      errorAsString += "Message: " + csvError.message + "\n";
      errors.push(errorAsString);
    }
    this.clipboard.copy(errors.join("\n"));
    await this.notificationService.success("Copied to clipboard");
  }

  gotoStep(step: number) {
    this.step = step;
  }
}
