import { Component, Input, OnInit } from '@angular/core';
import { GridxDeviceService } from '../../../services/gridx-device.service';
import * as dayjs from 'dayjs';
import { NotificationService } from '../../../services/notification.service';
import { GridxOSVersion } from '../../../domain/gridx-osversion.interface';
import { GatewayDeployment } from '../../../domain/gateway-deployment.interface';
import { ImageOSVersion } from '../../../domain/image-osversion.interface';
import { DeploymentService } from '../../../services/deployment.service';
import { OSVersionService } from '../../../services/os-version.service';
import {combineLatest, combineLatestAll, Observable} from "rxjs";
import {AuthService} from "../../../services/auth.service";
import {ImageVersionService} from "../../../services/image-version.service";
import {DeploymentImage} from "../../../domain/deployment-image.interface";

@Component({
  selector: 'eis-gateway-gateway-software-update-config',
  templateUrl: './gateway-software-update-config.component.html',
  styleUrls: ['./gateway-software-update-config.component.scss']
})
export class GatewaySoftwareUpdateConfigComponent implements OnInit {

  @Input()
  public serial: string;

  @Input()
  public gatewayDeployment: GatewayDeployment;

  public currentImageDate: dayjs.Dayjs | null;
  public latestImageDate: dayjs.Dayjs | null;
  public needsImageUpdate: boolean | undefined;
  public differenceInDays: number | undefined;
  public differenceInDaysForOs: number | undefined;
  public osUpdateTriggered: boolean = false;
  public imageOsVersion: ImageOSVersion;
  public isAdmin: boolean;
  public latestRcImageDate: string;

  constructor(
    private notificationService: NotificationService,
    private gridxService: GridxDeviceService,
    private osVersionService: OSVersionService,
    private imageVersionService: ImageVersionService,
    private deploymentService: DeploymentService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.loadGatewayDeployment();

    this.authService.getRoles().subscribe(roles => {
      this.isAdmin = roles.indexOf("eisadmin.admin") > -1;
      this.imageVersionService.getLatestRcImage().subscribe(image => {
        this.latestRcImageDate = dayjs(image.imageDate).format("YYYYMMDD");
      })
    });
  }

  private loadGatewayDeployment() {
    this.deploymentService.getGatewayDeployment(this.serial).subscribe(deployment => {
      this.gatewayDeployment = deployment;
      this.checkUpdate();
    });
  }

  private checkUpdate(){
    combineLatest([
      this.osVersionService.getLatestOSVersion(),
      this.imageVersionService.getImageVersion(this.gatewayDeployment.imageVersion)
    ]).subscribe( data => {
        if (data[1] == null || data[1].imageName === null && data[1].imageDate === null) {
          this.needsImageUpdate = false
        } else {
          this.latestImageDate = dayjs(data[0].imageDate)
          this.currentImageDate = dayjs(data[1].imageDate)
          this.needsImageUpdate = this.currentImageDate.isBefore(this.latestImageDate, 'day')
          this.differenceInDays = this.latestImageDate.diff(this.currentImageDate, 'day');
        }

        this.imageOsVersion = data[0];
        const currentOsVersionDate = dayjs(this.gatewayDeployment.osVersion, "yyyy.MM.dd");
        const latestOSVersionDate = dayjs(data[0].osVersion, "yyyy.MM.dd");
        this.differenceInDaysForOs = latestOSVersionDate?.diff(currentOsVersionDate, 'day');
        this.osUpdateTriggered = this.gatewayDeployment.targetVersion != undefined &&
          this.compareOSVersion(data[0].osVersion, this.gatewayDeployment?.targetVersion) == 0 &&
          this.compareOSVersion(this.gatewayDeployment?.osVersion, this.gatewayDeployment?.targetVersion) == -1;
      })
  }

  installLatestImage() {
    this.gridxService.updateGatewayDeployment(this.serial).subscribe({
      next: (_) => {
        this.notificationService.success("gateway-software-update-config.update-success");
        const parts = this.imageOsVersion.imageName.split(":");
        this.gatewayDeployment.imageVersion = parts.length == 2 ? parts[1] : this.imageOsVersion.imageName;
        this.checkUpdate();
      },
      error: (err) => this.notificationService.failure("gateway-software-update-config.update-error")
    });
  }

  installLatestRCImage() {
    this.gridxService.updateGatewayDeployment(this.serial, true).subscribe({
      next: (_) => {
        this.notificationService.success("gateway-software-update-config.update-success");
        const parts = this.imageOsVersion.imageName.split(":");
        this.gatewayDeployment.imageVersion = parts.length == 2 ? parts[1] : this.imageOsVersion.imageName;
        this.checkUpdate();
      },
      error: (err) => this.notificationService.failure("gateway-software-update-config.update-error")
    });
  }

  updateGatewayOS() {
    this.gridxService.updateGateWayOS(this.serial, this.imageOsVersion?.osVersion!).subscribe(
      (gridxImage) => {
        this.gatewayDeployment.osVersion = this.imageOsVersion.osVersion;
        this.gatewayDeployment.targetVersion = this.imageOsVersion.osVersion;
        this.checkUpdate();
        this.notificationService.success("gateway-details.os-update-triggered-success");
      },
      (err) => {
        let errorMessage = "gateway-details.os-update-triggered-error";
        if(typeof err.error == "string") {
          errorMessage = err.error;
        }
        this.notificationService.failure(errorMessage);
      }
    );
  }

  private compareOSVersion(currentVersion: string | undefined, latestVersion: string | undefined) : number {
    if(currentVersion == undefined && latestVersion == undefined) {
      return 0
    }

    if(latestVersion == undefined) {
      return 1;
    }

    if(currentVersion == undefined) {
      return -1;
    }

    return currentVersion.localeCompare(latestVersion, undefined, { numeric: true, sensitivity: 'base' });
  }
}
