import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {GridxDeviceService} from "../../../services/gridx-device.service";
import {Observable} from "rxjs";
import {GridxDevice} from "../../../domain/gridx-device.interface";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import 'dayjs/locale/de'
import {EonIotService} from "../../../services/eon-iot.service";
import {GridxDeviceReadyValidator} from "../../../components/validators/gridx-device-ready.validator";
import {GridxDeviceStartcodeValidator} from "../../../components/validators/gridx-device-startcode.validator";
import {EiotGatewayNotInUseValidator} from "../../../components/validators/eiot-gateway-not-in-use.validator";
import { TranslateService } from '@ngx-translate/core';
import {environment} from "../../../../environments/environment";

export interface DialogData {
  activeTenant: string;
}

@Component({
  selector: 'eis-gateway-add-gateway-dialog',
  templateUrl: './add-gateway-dialog.component.html',
  styleUrls: ['./add-gateway-dialog.component.scss']
})
export class AddGatewayDialogComponent implements OnInit {

  public step: number = 1

  public device: GridxDevice | null = null;
  public locationInput: FormControl<string | null>;
  public serialInput: FormControl<string | null>;
  public deviceData: FormGroup
  public isProduction: boolean


  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<AddGatewayDialogComponent>,
              public gridxService: GridxDeviceService,
              private eonIotService: EonIotService,
              private gridxDeviceReadyValidator: GridxDeviceReadyValidator,
              private gridxDeviceStartcodeValidator: GridxDeviceStartcodeValidator,
              private fb: FormBuilder,
              private translateService: TranslateService,) {

    this.isProduction = environment.production

    this.serialInput = new FormControl('', [Validators.required, Validators.pattern("^G\\d{3}-\\d{3}-\\d{3}-\\d{3}-\\d{3}-.-.$")],
      [
        EiotGatewayNotInUseValidator.createValidator(this.data.activeTenant, this.eonIotService),
        this.gridxDeviceReadyValidator.validate.bind(this.gridxDeviceReadyValidator)
      ]);


    this.locationInput = new FormControl('', Validators.required);

    this.deviceData = this.fb.group({
        serialInput: this.serialInput,
        startcodeInput: ['', [Validators.required, Validators.pattern("^\\w{4}-\\w{4}-\\w{4}-\\w{4}$")]]
      }, {
        asyncValidators: this.gridxDeviceStartcodeValidator.validate.bind(this.gridxDeviceStartcodeValidator)
      }
    )
  }

  ngOnInit(): void {
  }

  public closeDialog(result?: boolean) {
    this.dialogRef.close(result);
  }

  confirmGateway() {
    let tenant = this.data.activeTenant;
    let serial = this.serialInput.value
    let startcode = this.deviceData.get('startcodeInput')?.value.replaceAll(/-/g, '')
    let location = this.locationInput.value


    if (serial == null || location == null)
      return

    this.eonIotService.addGateway(tenant, serial, startcode, location)
      .subscribe({
        next: () => this.closeDialog(true),
        error: (error) => {
          if(error.status == 409) {
            error = this.translateService.instant("add-gateway-dialog.inputs.boarding.error");
          }

          this.closeDialog(error)
        }
      });
  }

  getFromNowTimestamp(date?: Date): string {

    dayjs.extend(relativeTime);
    dayjs.locale('de');

    return date != null ? dayjs(date).fromNow() : 'Nie';
  }

  goToNextStep() {
    this.step = this.step + 1
  }

  protected readonly environment = environment;
}
