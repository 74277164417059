import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import { OPCUADevice } from '../domain/opcua-device';
import { triggerLoader } from '../components/decorators/loaderDecorator';
import { checkFullyConnected } from '../components/decorators/checkConnectedDecorator';
import { OPCUATreeNode } from '../domain/opcua-tree-node';
import { OPCUADataPoint } from '../domain/opcua-data-point';
import { OPCUAConfig } from '../domain/opcua-config';
import { OPCUAEIoTDataPoint } from '../domain/opcua-eiot-data-point';
import { BacnetDevice } from '../domain/bacnet-device';
import { BacnetScanResult } from '../domain/bacnet-scan-result';

@Injectable({
  providedIn: 'root'
})
export class BacnetDeviceService {
  private readonly SERVICE_URL = `${environment.backendUrl}/bacnet`;

  constructor(private http: HttpClient) {
  }

  public getAllDevices(gatewaySerial?: string): Observable<BacnetDevice[]> {
    return this.http.get<BacnetDevice[]>(this.SERVICE_URL + '/' + gatewaySerial);
  }

  @triggerLoader
  @checkFullyConnected
  addDevice(device: BacnetDevice) {
    return this.http.post<BacnetDevice>(this.SERVICE_URL, device);
  }

  updateDevice(device: BacnetDevice) {
    return this.http.put<BacnetDevice>(this.SERVICE_URL, device);
  }

  deleteDevice(device: BacnetDevice, deleteInEoit: boolean) {
    return this.http.delete(this.SERVICE_URL + '/' + device.id + "?deleteInEiot=" + deleteInEoit);
  }

  startScan(gatewaySerial: string, timeOut: number) {
    return this.http.post<boolean>(this.SERVICE_URL + '/start-scan/' + gatewaySerial + '?timeOut=' + timeOut, {});
  }

  getScanResult(gatewaySerial: string) {
    return this.http.post<BacnetScanResult>(this.SERVICE_URL + '/scan-result/' + gatewaySerial, {});
  }
}
