<div mat-dialog-title>
  <eon-ui-icon fxLayout fxLayoutAlign="end" mat-dialog-close class=closeX name="close"></eon-ui-icon>
  <div class="headline">{{'add-gateway-dialog.headline' | translate}}</div>
</div>

<ng-container *ngIf="step == 1">
  <mat-dialog-content fxFlex>
    <eis-gateway-step-indicator details="{{'add-gateway-dialog.stepper.serial' | translate}}" [step]=1></eis-gateway-step-indicator>
    <div fxLayout="column">
      <p class="stepheadline">{{'add-gateway-dialog.stepper.serial.headline' | translate}}</p>
      <div class="tooltip-div">
        <eon-ui-info-icon>
          <eon-ui-tooltip slot="tooltip">
            <eon-ui-image src="assets/images/Gateway-sn.png" ratio="ratio-16x9"></eon-ui-image>
            <eon-ui-text text-style="quote">{{'general.tooltip.quote' | translate}}</eon-ui-text>
            <eon-ui-text text-style="subheadline">{{'add-gateway-dialog.stepper.serial.tooltip.subheadline' | translate}}</eon-ui-text>
            <eon-ui-text text-style="abstract">
              {{'add-gateway-dialog.stepper.serial.tooltip.abstract' | translate}}
            </eon-ui-text>
          </eon-ui-tooltip>
        </eon-ui-info-icon>
        <span>{{'general.help' | translate}}</span>
      </div>

      <div>
        <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
          <input [formControl]="serialInput"
                 matInput
                 placeholder="GXX-504-000-000-XXX-P-X"
                 class="eon-style-input"
                 type="text"
          >
          <mat-error *ngIf="serialInput.invalid">{{'add-gateway-dialog.inputs.serial.error' | translate}}</mat-error>
          <mat-error *ngIf="serialInput.hasError('eiotGatewayNotInUse')">{{'add-gateway-dialog.inputs.boarding.error' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <eis-gateway-checkmark [hidden]="!serialInput.valid"></eis-gateway-checkmark>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}" mat-dialog-close></eon-ui-button>
    <eon-ui-button (click)="goToNextStep()" [disabled]="!serialInput.valid"
                   text="{{'add-gateway-dialog.stepper.move-step-2' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>


<ng-container *ngIf="step == 2">
  <mat-dialog-content fxFlex>
    <eis-gateway-step-indicator details="{{'add-gateway-dialog.stepper.startcode' | translate}}" [step]=2></eis-gateway-step-indicator>
    <div>
      <p class="stepheadline">{{'add-gateway-dialog.stepper.startcode.headline' | translate}}</p>
      <div class="tooltip-div">
        <eon-ui-info-icon>
          <eon-ui-tooltip slot="tooltip">
            <eon-ui-image src="assets/images/Gateway-sn.png" ratio="ratio-16x9"></eon-ui-image>
            <eon-ui-text text-style="quote">{{'general.tooltip.quote' | translate}}</eon-ui-text>
            <eon-ui-text text-style="subheadline">{{'add-gateway-dialog.stepper.startcode.tooltip.subheadline' | translate}}</eon-ui-text>
            <eon-ui-text text-style="abstract">
              {{'add-gateway-dialog.stepper.startcode.tooltip.abstract' | translate}}
            </eon-ui-text>
          </eon-ui-tooltip>
        </eon-ui-info-icon>
        <span>{{'general.help' | translate}}</span>
      </div>


      <div>
        <form [formGroup]="deviceData">
          <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
            <input formControlName="startcodeInput"
                   matInput
                   placeholder="XXXX-XXXX-XXXX-XXXX"
                   class="eon-style-input"
                   type="text"
            >
            <mat-error *ngIf="deviceData.invalid">{{'add-gateway-dialog.inputs.startcode.error' | translate}}</mat-error>
          </mat-form-field>
        </form>
      </div>
      <eis-gateway-checkmark [hidden]="!deviceData.valid"></eis-gateway-checkmark>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}" mat-dialog-close></eon-ui-button>
    <eon-ui-button (click)="goToNextStep()" [disabled]="deviceData.invalid" text="{{'add-gateway-dialog.stepper.move-step-3' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>

<ng-container *ngIf="step == 3">
  <mat-dialog-content fxFlex>
    <eis-gateway-step-indicator details="{{'add-gateway-dialog.stepper.location-name' | translate}}" [step]=3></eis-gateway-step-indicator>
    <div>
      <p class="stepheadline">{{'add-gateway-dialog.stepper.location-name.headline' | translate}}</p>
        <mat-form-field appearance="outline" class="eon-style-form-field" color="accent">
          <input [formControl]="locationInput"
                 matInput
                 placeholder="{{'general.location-name' | translate}}"
                 class="eon-style-input"
                 type="text"
          >
        </mat-form-field>
      </div>
      <div class="warning">
        <eon-ui-info-icon>
        </eon-ui-info-icon>
        {{'add-gateway-dialog.stepper.online.warning' | translate}}</div>
      <div>
      <eis-gateway-checkmark [hidden]="!locationInput.valid"></eis-gateway-checkmark>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions style="justify-content: space-between;">
    <eon-ui-button scheme="redInverted" icon="close" text="{{'general.cancel' | translate}}" mat-dialog-close></eon-ui-button>
    <eon-ui-button (click)="confirmGateway()" [disabled]="locationInput.invalid"
                   text="{{'general.button.complete-process' | translate}}"></eon-ui-button>
  </mat-dialog-actions>

</ng-container>


