import {Component, Inject, OnInit} from '@angular/core';
import {Device} from "../../../../domain/device.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DeviceService} from "../../../../services/device.service";

@Component({
  selector: 'eis-gateway-delete-device-dialog',
  templateUrl: './delete-device-dialog.component.html',
  styleUrls: ['./delete-device-dialog.component.scss']
})
export class DeleteDeviceDialogComponent implements OnInit {
  device: Device;

  deleteDatapoints: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { device: Device },
              public dialogRef: MatDialogRef<DeleteDeviceDialogComponent>,
              private deviceService: DeviceService) {

  }

  ngOnInit(): void {
    this.device = this.data.device;
  }

  deleteDevice() {
    this.deviceService.deleteDevice(this.device, this.deleteDatapoints)
      .subscribe(value => this.dialogRef.close({
        success: true,
        deletedDataPoints: value
      }), error => this.dialogRef.close({success: false, error: error}))
  }
}
