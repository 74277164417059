<div mat-dialog-title class="dialog-title">
  <eon-ui-icon fxLayout fxLayoutAlign="end" [mat-dialog-close]="{status: 'closed'}" class=closeX
               name="close"></eon-ui-icon>
  <div class="headline">{{'add-opcua-browser-dialog.title' | translate}}</div>
</div>

<div mat-dialog-content fxFlex class="dialog-content">
  <div fxLayout="row" fxFlex>
    <div class="tree-container">
      <mat-spinner class="initial-loader" diameter="48" strokeWidth="2" color="warn" *ngIf="loadingInitialTree"></mat-spinner>
      <mat-tree class="device-tree" [dataSource]="treeDataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node;"
                       [class.selected]="node.id == activeNode?.id"
                       [class.data-point]="node.nodeClass == 'Variable'"
                       matTreeNodePadding
                       matTreeNodeToggle (click)="activeNode = node">
          <button mat-icon-button matTreeNodeToggle>
            <mat-icon class="mat-icon-rtl-mirror" *ngIf="node.expandable">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-spinner diameter="16" strokeWidth="1" color="warn" *ngIf="node.isLoading"></mat-spinner>
          <mat-icon class="folder-icon" *ngIf="node.nodeClass == 'Object' && !node.isLoading">folder_open</mat-icon>
          <mat-icon class="folder-icon" *ngIf="node.nodeClass == 'Variable' && !node.isLoading">timeline</mat-icon>
          <div class="node-container" [class.data-point]="node.nodeClass == 'Variable'">
            <div class="node-name-container">
              <label>{{node.name}}</label>
              <eon-ui-icon name="info_secondary" size="small" scheme="turquoise" *ngIf="node.description" (click)="$event.stopPropagation()">
                <eon-ui-tooltip slot="tooltip" placement="top">
                  <eon-ui-text>{{node.description}}</eon-ui-text>
                </eon-ui-tooltip>
              </eon-ui-icon>
            </div>

            <eon-ui-link *ngIf="node.nodeClass == 'Variable'" size="small" icon="add" text="{{'general.add' | translate}}" (click)="loadDataPoints($event, node)"></eon-ui-link>
          </div>
        </mat-tree-node>
      </mat-tree>
    </div>


    <div class="table-container">
      <table mat-table [dataSource]="dataPoints" matSort>
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="toggleAllRows()"
                          [checked]="isAllSelected()"
                          [indeterminate]="deviceAddedDataPoints.length > 0 && !isAllSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="toggle(row)"
                          [checked]="isSelected(row)">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="parentName">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="parentName">{{'opcua-datapoint-table.header.parentname' | translate }}</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{dataPoint.parent_name}}
          </td>

        </ng-container>

        <ng-container matColumnDef="dataType">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="dataType">Data Type</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{dataPoint.data_type}}
          </td>

        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="name">Name</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{dataPoint.name}}
          </td>

        </ng-container>

        <ng-container matColumnDef="nodeId">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="nodeId">{{'opcua-datapoint-table.header.node-id' | translate}}</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{dataPoint.node_id}}
          </td>

        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="value">Value</th>

          <td mat-cell *matCellDef="let dataPoint">
            {{formatValue(dataPoint) ?? 'n/a'}}
          </td>

        </ng-container>

        <ng-container matColumnDef="unit">
          <th mat-header-cell
              *matHeaderCellDef
              mat-sort-header="unit">Unit</th>

          <td mat-cell *matCellDef="let dataPoint">
            <eon-ui-link hide-icon="true"
                         (click)="showUnitDialog(dataPoint)"
                         size="small"
                         [text]="dataPoint.unit ?? 'none'"></eon-ui-link>
          </td>

        </ng-container>

        <ng-container matColumnDef="pollingFrequency">
          <th mat-header-cell *matHeaderCellDef>{{"mbus-config-table.header.polling-frequency" | translate}}</th>

          <td mat-cell *matCellDef="let dataPoint">
            <mat-form-field appearance="outline" class="eon-style-form-field no-padding polling-frequency-field">
              <input type="number" matInput [(ngModel)]="pollingFrequencies[dataPoint.node_id]">
            </mat-form-field>
          </td>

        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>

          <td mat-cell *matCellDef="let dataPoint">
            <eon-ui-icon *ngIf="isTemporary(dataPoint)" name="trash_bin" scheme="red" (click)="deleteDataPoint(dataPoint)"></eon-ui-icon>
          </td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          [class.removed]="isDeleted(row)"
          [class.added]="isNew(row)"
          *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

<div mat-dialog-actions style="justify-content: space-between;">
  <div></div>
  <eon-ui-button [text]="'general.save' | translate"
                 icon="save" scheme="red"
                 (click)="applyChanges()"></eon-ui-button>
</div>

