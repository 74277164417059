import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { OSVersionService } from '../../../services/os-version.service';
import { MatSort } from '@angular/material/sort';
import { GatewayOSVersion } from '../../../domain/gateway-osversion.interface';
import { NotificationService } from '../../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { AddVersionDialogComponent } from './add-version-dialog/add-version-dialog.component';
import { DeleteVersionDialogComponent } from './delete-version-dialog/delete-version-dialog.component';

@Component({
  selector: 'eis-gateway-os-version',
  templateUrl: './os-version.component.html',
  styleUrls: ['./os-version.component.scss']
})
export class OsVersionComponent implements OnInit {

  public displayedColumns = ['latestVersion', 'actions'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private osVersionService: OSVersionService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.loadOSVersion();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  loadOSVersion() {
    this.osVersionService.getAllOSVersions().subscribe(res => {
      this.dataSource.data = res;
    });
  }

  addOSVersion() {
    this.openAddDialog(null)
  }

  editOSVersion(version: GatewayOSVersion) {
    this.openAddDialog(version)
  }

  deleteOSVersion(version: GatewayOSVersion) {
    const dialogRef = this.dialog.open(DeleteVersionDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      data: {
        osVersion: version,
      },
      width: '592px',
      height: '564px'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result == "") {
        return;
      }

      this.loadOSVersion();
      if (result.success) {
        this.notificationService.success('delete-version.snackbar.success');
      } else {
        this.notificationService.failure('delete-version.snackbar.error');
      }
    });
  }

  private openAddDialog(osVersion: GatewayOSVersion | null) {
    const dialogRef = this.dialog.open(AddVersionDialogComponent, {
      panelClass: 'dialog-container-custom',
      disableClose: true,
      width: '592px',
      height: '540px',
      autoFocus: false,
      data: {
        osVersion: osVersion,
      }
    });
    dialogRef.afterClosed().subscribe((response) => {
      this.loadOSVersion();
      switch (response.status) {
        case 200:
          this.notificationService.success(osVersion == null ? 'os-version.snackbar.version-add.success' : 'os-version.snackbar.version-update.success');
          break;
        case 409:
          this.notificationService.failure('unit-conversion.snackbar.conflict');
          break;
        case "closed":
          break;
        default:
          this.notificationService.failure(osVersion == null ? 'os-version.snackbar.version-add.error' : 'os-version.snackbar.version-update.error');
      }
    });
  }
}
