import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GatewayDeployment } from '../../../domain/gateway-deployment.interface';
import { GridxDeviceService } from '../../../services/gridx-device.service';
import * as dayjs from 'dayjs';
import { Router } from "@angular/router";
import { ImageOSVersion } from '../../../domain/image-osversion.interface';
import { Dayjs } from 'dayjs';

@Component({
  selector: 'eis-gateway-gateway-image-version',
  templateUrl: './gateway-image-version.component.html',
  styleUrls: ['./gateway-image-version.component.scss']
})
export class GatewayImageVersionComponent implements OnInit, OnChanges {

  @Input()
  gateway: GatewayDeployment

  @Input()
  imageOSVersion: ImageOSVersion

  public currentImageName: string;
  public currentImageFormattedDate: string | undefined;
  public hasImageUpdate: boolean = false;
  public differenceInDays: number | undefined;
  public loading: boolean = false;

  constructor(private gridxService: GridxDeviceService,
              public router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
        if(changes['gateway'].currentValue) {
          if(this.gateway.imageDate) {
            this.currentImageFormattedDate = dayjs(this.gateway.imageDate).format("YYYYMMDD");
          } else {
            this.currentImageFormattedDate = this.gateway.imageVersion;
          }

          this.hasImageUpdate = this.hasNewImage();
        }
    }

  ngOnInit(): void {
    this.currentImageName = this.gateway.imageVersion;

  }

  private hasNewImage() {
    const latestImageDate = dayjs(this.imageOSVersion.imageDate);

    if(this.gateway.imageDate != null && latestImageDate != null) {
      this.differenceInDays = latestImageDate?.diff(this.gateway.imageDate, 'day');
      return dayjs(this.gateway.imageDate).isBefore(latestImageDate);
    }

    return false;
  }

  openGatewayConfig($event: MouseEvent): void {
    $event.stopPropagation();
    this.router.navigate(['gateway/' + this.gateway.gatewaySerial + '/config/softwareupdate'])
  }
}
