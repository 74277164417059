import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EonIotService } from '../../services/eon-iot.service';
import { Announcement } from '../../domain/announcement';
import { Observable } from 'rxjs';

@Component({
  selector: 'eis-gateway-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit, OnChanges {
  @Input() tenant: String;

  public announcements: Array<Announcement> = [];

  constructor(
    private eiotService: EonIotService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(changes['tenant'].currentValue) {
        this.eiotService.getAnnouncements(this.tenant).subscribe(announcements => {
          this.announcements = this.filterDismissedAnnouncements(announcements);
        });
      }
  }

  ngOnInit(): void {
  }

  dismissAnnouncement(announcement: Announcement) {
    let dismissedAnnouncementIds = localStorage.getItem("dismissed-announcement-ids") ?? "";
    dismissedAnnouncementIds += "," + announcement.id;

    localStorage.setItem("dismissed-announcement-ids", dismissedAnnouncementIds);
    this.announcements = this.filterDismissedAnnouncements(this.announcements);
  }

  private filterDismissedAnnouncements(announcements: Array<Announcement>) : Array<Announcement> {
    let dismissedAnnouncementIds = localStorage.getItem("dismissed-announcement-ids") ?? "";
    return announcements.filter(a => dismissedAnnouncementIds.indexOf(a.id) == -1)
  }
}
