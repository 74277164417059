import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { interval, Subscription } from 'rxjs';
import { NotificationService } from '../../../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { BacnetDevice } from '../../../../domain/bacnet-device';
import { BacnetDeviceService } from '../../../../services/bacnet-device.service';

@Component({
  selector: 'eis-gateway-bacnet-device-table',
  templateUrl: './bacnet-device-table.component.html',
  styleUrls: ['./bacnet-device-table.component.scss']
})
export class BacnetDeviceTableComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  public gatewaySerial: string | undefined;

  @Input()
  public tenant: string | undefined;

  @Input()
  public location: string | undefined;

  @Output()
  public deviceSelected = new EventEmitter<any>();

  public devices: BacnetDevice[] = [];
  public devicesDataSource = new MatTableDataSource<BacnetDevice>([]);
  public displayedColumns: string[] = ['hasUndeployedDataPoints', 'deviceAddress', 'deviceId', 'objectName', 'description', 'vendorName', 'modelName', 'contextActions']

  public selectedDevice: BacnetDevice | undefined;
  public scanningDevices: boolean = false;
  public deviceScanStartDate: Date | null;
  public deviceScanEndDate: Date | null;
  public scanError: string | null;
  public ignoreDeviceErrors: boolean | null = false;
  private scanResultSubscription: Subscription | null;
  protected hasIntegratedDevices: boolean = false;
  private timeOut: number = 120;

  constructor(
    private bacnetService: BacnetDeviceService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  hasChanges(): boolean {
    return !!this.devices.find(d => d.hasUndeployedDataPoints);
  }

  scanBacnetDevices() {
    this.scanningDevices = true;

    this.devicesDataSource.data = [];

    this.bacnetService.startScan(this.gatewaySerial!, this.timeOut).subscribe(result => {
      if(result) {
        this.notificationService.success("bacnet-scan-start-success");
        this.getBacnetDevices();
        this.scanResultSubscription = interval(5 * 1000).subscribe(() => this.getBacnetDevices());
      } else{
        this.notificationService.failure("bacnet-scan-start-error");
      }
    }, (error) => {
      this.notificationService.failure(error.error);
      this.scanningDevices = false
    });
  }

  getBacnetDevices() {
    this.selectedDevice = undefined;
    this.bacnetService.getScanResult(this.gatewaySerial!).subscribe(scanResult => {
      this.devices =  scanResult.devices;
      this.devicesDataSource.data = this.devices;
      this.devicesDataSource.sort = this.sort;
      this.scanningDevices = scanResult.scanStatus != "done";
      if(!this.scanningDevices) {
        this.scanResultSubscription?.unsubscribe();
      }
    });
  }
}
