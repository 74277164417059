<eis-gateway-app-shell>
  <eis-gateway-announcements [tenant]="gatewayDeployment.tenant" *ngIf="gatewayDeployment != null"></eis-gateway-announcements>
  <div class="page-container">
    <div class="top-content-area">
      <eis-gateway-breadcrumb [breadcrumbItems] = breadcrumbData></eis-gateway-breadcrumb>
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div fxLayout="row" >
          <h1>{{gatewayDeployment?.gatewaySerial}}</h1>
          <eon-ui-icon name="info_secondary" scheme="red" (click)="toggleShowDetails()" matTooltip="Click to see more details">
            <eon-ui-tooltip slot="tooltip" placement="top" delay="300">
              <eon-ui-text>Click to see more details</eon-ui-text>
            </eon-ui-tooltip>
          </eon-ui-icon>
        </div>
        <div fxLayout="row" fxLayoutAlign="stretch" fxLayoutGap="24px">
          <eon-ui-button [text]="'gateway-details.page.button.gateway-config' | translate"
                         icon="settings"
                         rank="secondary"
                         (click)="openGatewayConfig('network')"></eon-ui-button>

          <eon-ui-button *ngIf="supportsDiagnostics"
                         [text]="'gateway-table.popupmenu.diagnostics' | translate"
                         icon="search"
                         rank="secondary"
                          (click)="openDiagnosticsDialog()"></eon-ui-button>
        </div>
      </div>
      <div class="gateway-details" *ngIf="showDetails">
        <p class="configMap" title="{{'general.copy-to-clipboard' | translate}}" (click)="copyToClipboard(gatewayDeployment?.configmapId)">ConfigMapId: {{gatewayDeployment?.configmapId}} <mat-icon>file_copy</mat-icon></p>
        <p class="imageName">
          Image: {{gatewayDeployment?.imageVersion}}
          <eon-ui-link *ngIf="hasImageUpdate" hide-icon="true" size="small" (click)="openGatewayConfig('softwareupdate')" text="{{'gateway-details.update-available' | translate}}"></eon-ui-link>
        </p>
        <p class="os-version">
          OS Version: {{gatewayDeployment?.osVersion}}
          <eon-ui-link *ngIf="hasOSUpdate" hide-icon="true" size="small" (click)="openGatewayConfig('softwareupdate')" text="{{'gateway-details.update-available' | translate}}"></eon-ui-link>
          <span *ngIf="osUpdateTriggered">
            ({{'gateway-details.os-update-triggered' | translate}})
          </span>
        </p>
      </div>
      <div fxLayout="row" fxLayoutGap="32px" class="status-container-area">
        <div fxLayout="row" class="status-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <mat-icon *ngIf="mergedGatewayDeployment?.online" class="wifi-on">wifi</mat-icon>
          <mat-icon *ngIf="!mergedGatewayDeployment?.online" class="wifi-off">wifi_off</mat-icon>
          <div fxLayout="column">
            <p class="status-headline" *ngIf="mergedGatewayDeployment?.online">Online</p>
            <p class="status-headline" *ngIf="!mergedGatewayDeployment?.online">Offline</p>
            <p class="status-subheadline">Status</p>
          </div>
        </div>
        <div fxLayout="row" class="status-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <mat-icon class="status-icon-2" svgIcon="eis-assets-icn-tenant"></mat-icon>
          <div fxLayout="column">
            <p class="status-headline">{{gatewayDeployment?.tenant}}</p>
            <p class="status-subheadline">Tenant</p>
          </div>
        </div>
        <div fxLayout="row" class="status-container" fxLayoutAlign="start center" fxLayoutGap="32px">
          <mat-icon class="status-icon-3">location_on</mat-icon>
          <div fxLayout="column">
            <p class="status-headline">{{gatewayDeployment?.location}}</p>
            <p class="status-subheadline">Location</p>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" class="bottom-content-area">
      <mat-tab-group dynamicHeight mat-stretch-tabs animationDuration="200ms" class="main-tab-group" [selectedIndex]="activeProtocolIndex" (selectedIndexChange)="saveActiveProtocol($event)">
        <mat-tab label="Modbus" class="modbus-tab">
          <ng-template mat-tab-label>
            <div class="tab-label-container">
              <label>Modbus</label>
              <div class="mobile-device-counter with-tag"><label class="tag">TCP</label><img src="assets/icons/mobile.svg"> {{mergedGatewayDeployment?.modbusTCPDeviceCount}}&nbsp;<mat-icon>timeline</mat-icon> {{mergedGatewayDeployment?.modbusTCPDatapointCount}}</div>
              <div class="mobile-device-counter with-tag"><label class="tag">RTU</label><img src="assets/icons/mobile.svg"> {{mergedGatewayDeployment?.modbusRTUDeviceCount}}&nbsp;<mat-icon>timeline</mat-icon> {{mergedGatewayDeployment?.modbusRTUDatapointCount}}</div>
            </div>
          </ng-template>
          <mat-tab-group #modbusTabGroup [selectedIndex]="modbusSelectedTabIndex" dynamicHeight mat-stretch-tabs animationDuration="200ms" class="tab-group">
            <mat-tab label="Devices">
              <div *ngIf="devices">
                <div class="add-device-container" fxLayout="row" fxLayoutAlign="space-between center">
                  <div>
                    {{deviceAmount}} Device(s) found
                  </div>
                  <div>
                    <eon-ui-button [text]="'gateway-details.page.button.add-device' | translate"
                                   icon="add" scheme="red"
                                   (click)="openAddDeviceDialog()"></eon-ui-button>
                  </div>

                </div>
                <eis-gateway-device-table [tenant]="gatewayDeployment?.tenant" [devices]="devices" [gatewaySerial]="serial"
                                          (updateDatasource)="refresh()"></eis-gateway-device-table>
              </div>
            </mat-tab>
            <mat-tab label="Data Points">
              <div *ngIf="datapoints" class="datapoint-table">
                <eis-gateway-datapoint-table [tenant]="gatewayDeployment?.tenant" [datapoints]="datapoints"
                ></eis-gateway-datapoint-table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab label="M-Bus">
          <ng-template mat-tab-label>
          <div class="tab-label-container">
            <label>M-Bus</label>
            <div class="mobile-device-counter"><img src="assets/icons/mobile.svg"> {{mergedGatewayDeployment?.mbusDeviceCount}}</div>
            <div class="mobile-device-counter"><mat-icon>timeline</mat-icon> {{mergedGatewayDeployment?.mbusDatapointCount}}</div>
          </div>
          </ng-template>

          <mat-tab-group #mbusTabGroup [selectedIndex]="mbusSelectedTabIndex" dynamicHeight mat-stretch-tabs animationDuration="200ms" class="tab-group">
            <mat-tab label="{{'gateway-details.mbus.tab.configurator' | translate}}">
              <eis-gateway-mbus-device-configurator [tenant]="gatewayDeployment?.tenant" [gatewaySerial]="serial" [location]="gatewayDeployment?.location"></eis-gateway-mbus-device-configurator>
            </mat-tab>
            <mat-tab label="{{'gateway-details.mbus.tab.datapoints' | translate}}">
              <div class="datapoint-table">
                <eis-gateway-mbus-detailed-datapoint-table [tenant]="gatewayDeployment?.tenant" [datapoints]="mbusDatapoints" [gatewaySerial]="serial"
                ></eis-gateway-mbus-detailed-datapoint-table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab label="OPC UA">
          <ng-template mat-tab-label>
            <div class="tab-label-container">
              <label>OPC UA</label>
              <div class="mobile-device-counter"><img src="assets/icons/mobile.svg"> {{mergedGatewayDeployment?.opcuaDeviceCount}}</div>
              <div class="mobile-device-counter"><mat-icon>timeline</mat-icon> {{mergedGatewayDeployment?.opcuaDatapointCount}}</div>
            </div>
          </ng-template>
          <mat-tab-group #opcuaTabGroup [selectedIndex]="opcuaSelectedTabIndex" dynamicHeight mat-stretch-tabs animationDuration="200ms" class="tab-group">
            <mat-tab label="Devices">
              <div *ngIf="devices">
                <eis-gateway-opcua-device-table [gatewaySerial]="serial"></eis-gateway-opcua-device-table>
              </div>
            </mat-tab>
            <mat-tab label="Data Points">
              <div class="datapoint-table">
                <eis-gateway-opcua-data-point-table [tenant]="gatewayDeployment?.tenant" [datapoints]="opcuaDatapoints" [gatewaySerial]="serial"
                ></eis-gateway-opcua-data-point-table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab label="BACnet">
          <ng-template mat-tab-label>
            <div class="tab-label-container">
              <label>BACnet</label>
              <div class="mobile-device-counter"><img src="assets/icons/mobile.svg"> {{mergedGatewayDeployment?.bacnetDeviceCount}}</div>
              <div class="mobile-device-counter"><mat-icon>timeline</mat-icon> {{mergedGatewayDeployment?.bacnetDatapointCount}}</div>
            </div>
          </ng-template>
          <mat-tab-group #opcuaTabGroup [selectedIndex]="opcuaSelectedTabIndex" dynamicHeight mat-stretch-tabs animationDuration="200ms" class="tab-group">
            <mat-tab label="Devices">
              <div *ngIf="devices">
                <eis-gateway-bacnet-device-table [gatewaySerial]="serial"></eis-gateway-bacnet-device-table>
              </div>
            </mat-tab>
            <mat-tab label="Data Points">
              <div class="datapoint-table">

              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</eis-gateway-app-shell>


